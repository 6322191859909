import { useStaticQuery, graphql } from "gatsby"

export const useNewsQuery = ()=> {

    return useStaticQuery(graphql`
    
        query NewsQuery {

            wpPage(title: {eq: "NEWS"}) {
                ACF_ResourcesPage {
                    resourcesHero {
                        animatedTitle
                        desktopImage {
                            localFile {
                                publicURL
                                childImageSharp {
                                    gatsbyImageData
                                }
                            }
                        }
                        mobileImage {
                            localFile {
                                childImageSharp {
                                    gatsbyImageData
                                }
                            }
                        }
                    }

                    infoBlock {
                        mainCopy
                        title
                    }
                }
            }
            
        }

    `)
}