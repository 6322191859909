import * as React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo/Seo"

import ResourcesHero from "../components/Resources/ResourcesHero/ResourcesHero"
import InfoBlock from "../components/Resources/InfoBlock/InfoBlock"
import PostsGrid from "../components/Resources/PostsGrid/PostsGrid"

import { useNewsQuery } from "../hooks/useNewsQueries"
import {GlobalDispatchContext } from "../../src/context/GlobalContextProvider"


const News = ({ children }) => {

  const data = useNewsQuery();
  const dispatch = React.useContext(GlobalDispatchContext)

  React.useEffect(() => {
    // cleanup function will be called when component is removed    
    // dispatch({type: 'SEARCH_PARAMS', payload: null})

    return () => {}
  },[]); 

  return(
    <Layout>
      <Seo title="News" />
      <ResourcesHero assets={data.wpPage.ACF_ResourcesPage.resourcesHero}/>
      <InfoBlock data={data.wpPage.ACF_ResourcesPage.infoBlock}/>
      <PostsGrid allCategories={['Blog', 'In the news', 'Press Releases']}/>
    </Layout>
  )

}

export default News
